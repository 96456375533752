<template>
 <div :class="windowClass">
   <v-container>


        <v-row>
            <v-col cols="12">
                  <v-card dense>
        <v-toolbar>
            Results
            <v-spacer/>
              
               <v-icon right color="primary" @click="form=true">mdi-keyboard</v-icon>
        </v-toolbar>
          <v-card-text>
              <v-row>
               <v-col cols="6">
                 <v-toolbar dense flat  color="primary" dark>NATIONAL</v-toolbar>
                   <table class="va-table ma-3">
                     <tr>
                       <th>DRAW ID</th>
                       <th>DRAW TIME</th>
                       <th>STATUS</th>
                     </tr>
                     <template v-for="(draw, index) in game3d" >
                     <tr :key="index" v-if="draw.status == 'Open' || draw.status == 'Ready'">
                       
                       <td class="border_bot">{{draw.id}}</td>
                       <td class="border_bot">{{draw.date}} {{draw.time}}</td>
                        <td class="border_bot">{{draw.status}}</td>
                     </tr>
                     </template>
                   </table>
               </v-col>

               <v-col cols="6">
                 <v-toolbar dense flat color="primary" dark>LOCAL</v-toolbar>
                   <table class="va-table ma-3">
                     <tr>
                       <th>DRAW ID</th>
                       <th>DRAW TIME</th>
                       <th>STATUS</th>
                     </tr>
                     <template v-for="(draw, index) in game3dlocal" >
                      <tr :key="index" v-if="draw.status == 'Open' || draw.status == 'Ready'">
                       
                       <td class="border_bot">{{draw.id}}</td>
                       <td class="border_bot">{{draw.date}} {{draw.time}}</td>
                        <td class="border_bot">{{draw.status}}</td>
                     </tr>
                     </template>
                   </table>
               </v-col>
              </v-row>
          </v-card-text>
          </v-card>
            </v-col>
        </v-row>
        <game-key-in :show="form" :game3dlocal="game3dlocal" :game3d="game3d" @DialogEvent="eKeyIn"></game-key-in>
   </v-container>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      form: false,
      report_type:'GAME GROSS OVERALL',
      loading: false,
      to_menu: false,
      game3d:[],
      game3dlocal: [],
      to_date:'',
        from_menu: false,
      from_date:'',
      items:[],
      areas:[]
      
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getDraws("3D")
           this.getDraws("SWER3")
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      eKeyIn(event){
        this.form = false
        console.log(event)
      },
      getDraws(game){
           this.$http.post("draw/get", {game:game, status:"per_game"}).then(response => {
              if(game =='3D') {
                 response.data.draws != null?this.game3d= response.data.draws:this.game3d = []
              } else {
                 response.data.draws != null?this.game3dlocal= response.data.draws:this.game3dlocal = []
              }
           
        }).catch(e => {
            console.log(e)
        })
      },
       getItems(){
          this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "win_tickets"
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.items = response.data.items:this.items =[]
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
      },
       dlToCsv(){
            this.DownloadToCsv("win-table", 'Winnings-' + this.from_date + '-to-' + this.to_date)
        }
    }
  }
</script>